import React from 'react'
import styled from '@emotion/styled'
import { PRIMARY_COLOR, WHITE_COLOR, BLACK_COLOR } from './color'
import FeatherIcon from 'feather-icons-react'
import bp from './breakpoints'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

export const ButtonNoStyle = styled.button`
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

export const ButtonNoStyleAlt = styled.button`
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.5rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

export const AdminDashboardButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 220px;
  right: 0;
  height: 40px;
  background-color: ${PRIMARY_COLOR};
  z-index: 999;
  padding-left: 1rem;
  padding-right: 1rem;
`

export const AdminDashboardActionButton = styled(ButtonNoStyle)`
  float: right;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  color: ${WHITE_COLOR};
  font-size: 1.125rem;
  text-transform: uppercase;
`
const LinkWrapper = styled(Link)`
  z-index: 1;
  width: 100%;
`
const MainButtonContainer = styled(motion.button)`
  outline: inherit;
  cursor: pointer;
  border: none;
  padding: 0;
  display: grid;
  grid-template-columns: 20% 80%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-family: 'Source Code Pro' !important;
  border-radius: 4px;
  height: 56px;
  background: ${BLACK_COLOR};
  font-weight: 600;
  color: ${WHITE_COLOR};
  letter-spacing: 0.115em;
  text-transform: uppercase;
  font-size: 12px;
  ${bp[2]} {
    display: flex;
    width: fit-content;
    height: 40px;
    justify-content: center;
  }
  ${bp[3]} {
    display: flex;
  }
`
const IconContainer = styled.div`
  padding: 0px 12px;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  background: ${PRIMARY_COLOR};
  ${bp[2]} {
    width: fit-content;
    height: 40px;
  }
`
const CallToAction = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  align-items: center;
  font-size: 1rem;
  ${bp[2]} {
    font-size: 12px;
    padding: 0px 16px;
  }
`
const SecondaryButtonContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 56px;
  border: 2px solid ${BLACK_COLOR};
  font-weight: 600;
  color: ${BLACK_COLOR};
  letter-spacing: 0.115em;
  text-transform: uppercase;
  font-family: 'Source Code Pro' !important;
  font-size: 12px;
  ${bp[2]} {
    width: fit-content;
    height: 40px;
  }
`
export const MainButton = ({ to, icon, children, style = {}, type = {}, id }) => {
  return (
    <LinkWrapper type={type} to={to} style={style} id={id}>
      <MainButtonContainer
        whileHover={{
          scale: 1.1,
          x: -10,
          transition: { duration: 0.3 },
        }}
        whileTap={{ scale: 0.9 }}
      >
        <IconContainer>
          <FeatherIcon icon={icon} stroke="white" />
        </IconContainer>
        <CallToAction>{children}</CallToAction>
      </MainButtonContainer>
    </LinkWrapper>
  )
}

export const SecondaryButton = ({ to, children, style = {} }) => {
  return (
    <LinkWrapper to={to} style={style}>
      <SecondaryButtonContainer
        whileHover={{
          scale: 1.1,
          transition: { duration: 0.3 },
        }}
        whileTap={{ scale: 0.9 }}
      >
        <CallToAction>{children}</CallToAction>
      </SecondaryButtonContainer>
    </LinkWrapper>
  )
}