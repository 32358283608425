import React from 'react'
import styled from '@emotion/styled'
import {
  BACKGROUND_COLOR_RGB,
  BLACK_COLOR,
  BLACK_COLOR_RGB,
  PRIMARY_COLOR,
} from './color'
import bp from './breakpoints'
import FeatherIcon from 'feather-icons-react'
import { MOBILE_DEVICE } from './breakpoints'
import { useMediaQuery } from '@react-hook/media-query'
import { Link } from 'gatsby'

const setWeight = (props) => {
  switch (props.weight) {
    case 'light':
      return 'light'
    case 'bold':
      return 'bold'
    case 'normal':
    default:
      return 'normal'
  }
}

export const Heading1 = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Manrope'
  text-transform: uppercase;
  text-align: left;
  ${bp[2]} {
    font-size: 3rem;
  }
`
export const HeadingWhite = styled.h1`
  margin: 0;
  color: white;
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;

  ${bp[2]} {
    font-size: 2.5rem;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
`

export const Heading2 = styled.h2`
  position: relative;
  margin: 0;
  color: ${BLACK_COLOR_RGB(0.6)};
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;

  ${bp[0]} {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 56px;
      height: 2px;
      background-color: ${PRIMARY_COLOR};
    }

    &::before {
      margin-right: 1rem;
      right: 100%;
    }

    &::after {
      margin-left: 1rem;
      left: 100%;
    }
  }

  ${bp[2]} {
    font-size: 1.5rem;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin: 0 auto;
  gap: 1rem;
  margin-bottom: 3rem;
  text-align: center;
`
const ContainerLeft = styled.div`
  display: grid;
  align-items: left;
  justify-content: left;
  justify-items: left;
  row-gap: 1.5rem;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  text-align: center;

  ${bp[0]} {
    max-width: 500px;
  }
  ${bp[0]} {
    max-width: 600px;
  }

  ${bp[2]} {
    max-width: 100%;
  }
`
const IconContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 3px solid ${BLACK_COLOR};
  border-radius: 100px;
  margin-right: 1rem;
  ${bp[2]} {
    width: fit-content;
    height: 40px;
  }
`

export const HeadingContainer = ({
  title = '',
  subtitle = '',
  style = {},
  icon,
}) => {
  const matchMobile = useMediaQuery(`(min-width: ${MOBILE_DEVICE}px)`)
  return (
    <Container style={style}>
      <IconContainer to={'/'} style={matchMobile ? { display: 'none' } : null}>
        <FeatherIcon icon={icon} stroke="black" />
      </IconContainer>
      <Heading1>{title}</Heading1>
    </Container>
  )
}

export const HeadingContainerWhite = ({
  title = '',
  subtitle = '',
  style = {},
}) => {
  return (
    <ContainerLeft style={style}>
      {subtitle && <Heading2>{subtitle}</Heading2>}
      <HeadingWhite>{title}</HeadingWhite>
    </ContainerLeft>
  )
}

export const Text = styled.p`
  margin: 0;
  font-weight: ${setWeight};
  font-size: 1rem;
  font-family: var(--secondary-font);
`

export const AdminTitle = styled.h1`
  margin: 0;
  font-size: 1.5rem;
`

export const AdminAuthor = styled.h4`
  margin: 0;
  margin-top: 0.25rem;
  font-size: 0.975rem;
  text-transform: uppercase;
  color: ${BACKGROUND_COLOR_RGB(0.65)};
`

export const AdminPostedAtDate = styled.h5`
  margin: 0;
  margin-top: 0.25rem;
  font-size: 0.85rem;
  color: ${BACKGROUND_COLOR_RGB(0.65)};
`

export const ItalicText = styled.p`
  margin: 0;
  margin-top: 2rem;
  font-size: 1rem;
  font-style: italic;
  font-family: 'source code pro'
  color: white;
  opacity: 0.6;
  font-weight: 200;
  margin-bottom: 24px;
  text-align: center;
  color: ${BLACK_COLOR};
`

export const Phrase = styled.h1`
  font-size: 2.5rem;
  width: 100%;
  font-family: 'Manrope' !important;
  color: ${BLACK_COLOR};
  font-weight: 700;
  margin-bottom: 3rem;
  position: relative;
  z-index: 10;
  @media (max-width: 812px) {
    font-size: 2.5rem;
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 576px) {
    font-size: 1.8rem;
  }
  ${bp[3]} {
    font-size: 5rem;
  }
`
export const WhitePaperText = styled(Text)`
  align-self: center;
  justify-self: center;
  max-width: 100%;
  font-size: 2rem;
  font-weight: 600;
  padding: 50px;
  font-family: 'Manrope';
`